import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import * as LeadActions from '@actions/LeadActions';
import * as SnackbarActions from '@actions/SnackbarActions';
import * as api from '@api';

export function* createLead(request) {
  try {
    const result = yield call(api.createLead, request.payload);
    yield put(LeadActions.createLead.success(result));
    yield put(
      SnackbarActions.showSnackbar('Successfully subscribed to updates!')
    );
  } catch (error) {
    yield put(SnackbarActions.showSnackbar(error));
    yield put(LeadActions.createLead.failure(error));
  }
}

/*
 * WATCHERS
 */

export function* watchCreateLead() {
  yield takeEvery(LeadActions.createLead.request, createLead);
}

export default function* root() {
  yield all([fork(watchCreateLead)]);
}
