import * as React from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

export const fontFamily = [
  'benton-sans',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(',');

const ThemeProvider: React.FC<{}> = ({ children }) => {
  const theme = createMuiTheme({
    typography: {
      fontFamily
    },
    palette: {
      primary: {
        main: '#2F3061'
      },
      secondary: {
        main: '#D4F2DB'
      },
      type: 'light'
    }
  });

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
